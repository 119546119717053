import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../templates/layout';
import MetaTags from '../components/MetaTags';

import {
  callService,
  roadmapService,
  launchpadService,
  architectureReviewService,
  testingAuditService,
} from '../../data/SiteConfig';
import { printGbpWithUsd } from '../utils/formatting';

const TITLE = 'Serverless Consulting Services';

const SERVICES = [
  {
    title: 'Clarity Call',
    href: '/services/call',
    priceGBP: callService.priceGBP,
    description: `You’re a CTO or VP/Director of Engineering who can see the benefits that
        adopting serverless could bring to your company’s delivery of
        software projects. But you’re not sure about how some of the specifics
        will look in practice. On this one-off call, I can help answer any
        questions you have and give you a direction to proceed.`,
    ctaText: 'Book now',
    imageKey: 'phone',
  },
  {
    title: 'Application Roadmap',
    href: '/services/roadmap',
    priceGBP: roadmapService.priceGBP,
    description:
      'You’re starting to build a new application on AWS serverless. You have limited experience with using it in production and want to avoid making costly mistakes at an early stage. I can help accelerate your development team by giving you a tailored plan of action for your architecture and development processes to take you through to production and beyond.',
    ctaText: 'Learn more & apply',
    imageKey: 'roadGreenfield',
  },
  {
    title: 'Architecture & Process Review',
    href: '/services/review/',
    priceGBP: architectureReviewService.priceGBP,
    pricePrefixText: 'From ',
    description:
      'You have an existing serverless app running on AWS but have some concerns about the scalability or maintainability of your architecture or development processes as your product grows. I can help by reviewing your codebase & architecture and interviewing your developers to identify risk areas and their causes. I will then recommend solutions and help you with their implementation.',
    ctaText: 'Learn more & apply',
    imageKey: 'stethoscope',
  },
  {
    title: 'Testing Audit',
    href: '/services/testing-audit',
    priceGBP: testingAuditService.priceGBP,
    description:
      'You’ve an AWS serverless application already running in production, but lack of a quality automated test suite is holding back your team’s ability to move fast with confidence. I will review your delivery pipelines and existing tests, and give you a prioritised plan of what and how to fix.',
    ctaText: 'Learn more & apply',
    imageKey: 'testTubes',
  },
  {
    title: 'DevOps Launchpad',
    href: '/services/launchpad',
    priceGBP: launchpadService.priceGBP,
    description:
      'You’re building a new product on AWS serverless. Your team’s skillset is focused on application development and you have limited or no cloud DevOps experience. I can build you a best-practice multi-account AWS environment, a scalable scaffolded codebase using the Serverless Framework with the common AWS serverless services all set up for you, along with automated delivery pipelines right through to production.',
    ctaText: 'Learn more & apply',
    imageKey: 'rocketLaunch',
  },
  {
    title: 'Guidance Program',
    href: '/services/guidance',
    description: `Following on from a Clarity Call, Roadmap, Review or Launchpad engagement, I offer an ongoing Guidance Program to support you and your company month-by-month on your serverless journey. Different packages are available based on your requirements.
    `,
    ctaText: 'Learn more',
    imageKey: 'mountainGuide',
  },
];

const PrintPrice = ({ priceGBP, prefixText = '' }) => (
  <span className="inline-block">
    {' '}
    {priceGBP && (
      <span>
        {prefixText}
        {printGbpWithUsd(priceGBP)}{' '}
      </span>
    )}
  </span>
);

const Page = ({ data, path }) => {
  const ServicesList = ({ services }) => (
    <div className="relative pt-4 pb-10 px-4 sm:px-6 lg:px-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="mx-auto grid gap-5 md:grid-cols-2 justify-center">
          {services.map((service) => (
            <div
              key={service.title}
              className="flex flex-col rounded-lg overflow-hidden border-2 border-gray-200 max-w-lg"
            >
              <div className="flex-shrink-0">
                {service.imageKey && (
                  <Img
                    className={`h-48 w-full object-cover ${service.imageCss}`}
                    fluid={data[service.imageKey].childImageSharp.fluid}
                    alt={service.title}
                  />
                )}
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1 mx-auto">
                  <a href={service.href} className="btn block mt-2">
                    <h2 className="font-semibold text-gray-900 text-center my-0">
                      {service.title}
                    </h2>
                    {service.priceGBP && (
                      <p className="text-gray-700 font-sans text-center text-base mt-2">
                        <PrintPrice
                          priceGBP={service.priceGBP}
                          prefixText={service.pricePrefixText}
                        />
                      </p>
                    )}
                    <p className="mt-3 text-base text-gray-800 leading-relaxed">
                      {service.description}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center mx-auto">
                  <a
                    href={service.href}
                    className="inline-block btn px-4 py-1 bg-primary-600 hover:bg-primary-500 text-white hover:text-white rounded-md mb-2 font-sans"
                  >
                    {service.ctaText} »
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <Layout>
      <MetaTags
        title="Serverless Consulting Services"
        description="Serverless architectural consultations, audits and DevOps services for AWS serverless apps."
        path={path}
      />
      <article className="mx-auto">
        <section className="mx-auto px-4 md:px-8">
          <h1 className="page-heading font-extrabold">{TITLE}</h1>
        </section>
        <section className="my-8">
          <ServicesList services={SERVICES} />
        </section>
        <section className="max-w-3xl mx-auto px-4 md:px-8">
          <p>
            If you cannot see a particular service that matches your needs, I
            can sometimes craft a custom engagement, so please{' '}
            <Link to="/contact/">contact me</Link> to see if we can work
            something out.
          </p>
          <p>
            You can also check out my{' '}
            <Link to="/case-studies/">case studies page</Link> to see how I’ve
            helped my other clients, or read the list of{' '}
            <Link to="/testimonials/">testimonials</Link> I have received.
          </p>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    testTubes: file(relativePath: { eq: "test-tubes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    rocketLaunch: file(relativePath: { eq: "rocket-launch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    roadGreenfield: file(relativePath: { eq: "road-greenfield.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    phone: file(relativePath: { eq: "rotary-phone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mountainGuide: file(relativePath: { eq: "mountain-guide.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Page;
